



















import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';
import Category from '@/models/Category.model';

@Component
export default class CategoryTreeSelectComponent extends mixins(BaseMixin) {
  @Prop({ required: true })
  public categories!: Category[];

  @Prop({ required: true })
  public value?: Category;   // Automatically set with v-model

  private selectedCategoryId: string = '';

  private allCategories: Category[] = [];

  // Ids of opened nodes:
  private openedNodeIds: string[] = [];

  private mounted() {
    if (this.value) {
      this.selectedCategoryId = this.value.id;
      this.openParentCategory(this.value);
    }
  }

  get items() {
    return this.getMappedCategories(this.categories);
  }

  /**
   * Opens parent category of the given category.
   */
  private openParentCategory(category: Category) {
    // Find parent category:
    const parentCategory: Category | undefined = this.allCategories.find(
      parentcategory => parentcategory.categories.find(subcategory => subcategory.id == category.id)
    );
    if (parentCategory) {
      this.openedNodeIds.push(parentCategory.id);
    }
  }

  private getMappedCategories(categories: Category[]): any[] {
    return categories.map(category => ({
      id: category.id,
      name: this.$t(`DEAL.CATEGORIES.${category.name}`),
      children: this.getMappedCategories(category.categories),
      selectable: category.categories.length == 0
    }));
  }

  private setSelectedCategory(id: string) {
    this.selectedCategoryId = id;
    const selectedCategory = this.allCategories.find(item => item.id == id);
    // Update v-model:
    this.$emit('input', selectedCategory);
    // Close expansion panel:
    this.$emit('closePanel');
  }

  @Watch('categories', { immediate: true })
  private onCategoriesChanged() {
    let categories: Category[] = this.categories.map(category => category.categories).reduce((pre, cur) => pre.concat(cur));
    // Add main categories:
    this.allCategories = categories.concat(this.categories);
  }
}
